import React, { Component } from 'react';
import { AuthContext } from "../../../components/Auth/AuthDataProvider.jsx";
import BreadCrumb from "../../../components/Navs/Breadcrumb"
import CustomCropper from '../../../components/Cropper/CustomCropper.jsx';
import CustomLabel from '../../../components/CustomFields/CustomLabel.jsx';
import CustomInput from '../../../components/CustomFields/CustomInput.jsx';
import { 
  Box,
  Grid,
  CircularProgress
} from '@material-ui/core';
import _ from 'lodash';
import apiUtil from "../../../api/apiUtil.jsx";
import api from "../../../api/api.jsx";
import wmsApi from "../../../api/wmsApi.jsx";
import ldsApi from "../../../api/ldsApi.jsx";
import { Container, Row, Col, Button, Card, Table, Modal } from 'react-bootstrap';
import moment from 'moment';
import {RoleAccessService} from "../../../data/role-access";
import CustomSelectOption from '../../../components/CustomFields/CustomSelectOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faFileLines, faTrash } from '@fortawesome/free-solid-svg-icons'
import countryList from '../../../assets/countryList.json';
import Api from "../../../api/api.jsx";
import CustomDialog from '../../../components/Dialog/CustomDialog.jsx';
import CustomGoogleMapSearch from "../../../components/GoogleMap/CustomGoogleMapSearch.jsx";

class CustomerSetting extends Component {
  static contextType = AuthContext;
    currentRole = window.localStorage.getItem('current_role');
    user = JSON.parse(window.localStorage.getItem('user'))
    sortCountry = countryList.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    allCountry  = this.sortCountry.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label))===i)
    
    preCountry = window.localStorage.getItem('Country') || null;
    preTimeZone = window.localStorage.getItem('TimeZone') || null;
    preTimeZoneList = window.localStorage.getItem('TimeZoneList')? JSON.parse(window.localStorage.getItem('TimeZoneList')) : [];
  constructor(props){
    super(props);
    
    this.state = {
      isLoading: false,
      data: [],
      country: this.preCountry,
      countryCode: "",
      currentRole: this.currentRole,
      user: this.user,
      countryIdentifier: "",
      timeZone: this.preTimeZone,
      timeZoneList: this.preTimeZoneList,
      personalFirstName: "",
      personalLastName: "",
      personalNameValidated: false,
      personalEmail: "",
      personalEmailValidated: false,
      personalPassword: window.localStorage.getItem("password"),
      newPassword: "",
      retryPassword: "",
      openDeleteDialog: false,
      personalProfileImageURL: "",
      personalProfileOldData: "",
      personalContactNo:"",
      contactnoValidated: false,
      organizationId: "",
      address: [],
      cloneAddress: [],
      openCreateAddress: false,
      update_data: null,
      customerItems: null,
      customerItemsIsLoading:false,
      postal_code_validated: false,
      create_data: {
        name: null,
        address: null,
        latitude: null,
        longitude: null,
        customer_id: null,
        application_company_id: null,
        postal_code: null,
        building_no: null,
      },
    }
  }
  componentDidMount() {
    this.callReadSettingsApi();
    this.callAddressApi();
    // this.callCustomerReadApi(()=> {
    // });
  }

    /* CUSTOMER API */
    callCustomerReadApi = (callback = null) => {
    const { accessToken, isOnBoarding, onBoardingCustomer } = this.context;
        let param = {
            is_active: true,
            invoice_light_data: true
        };
        this.setState({
            customerItemsIsLoading : true
        })            
        wmsApi.read('customers', param, accessToken)
            .then((result) => {
            apiUtil.parseResult(result, (data) => {
                let result = (data.result && data.result.length > 0) ? data.result : [];

                let arr = [];
                if(result && result.length > 0){
                
                for(let i = 0; i < result.length; i++){
                    let item = result[i];
                    
                    let value = item.id;
                    let label = item.company_name;

                    if(arr.findIndex(x => x.value === value) === -1){
                    arr.push({
                        value: value,
                        label: label,
                        item: item
                    });
                    }
                }

                this.setState({
                    customerItemsIsLoading: false,
                    customerItems: arr.sort((a, b) => {
                      const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                    
                      // names must be equal
                      return 0;
                    }),
                }, () => {
                    if(callback){
                        callback();
                    }
                    });

                } else {
                this.setState({
                    customerItemsIsLoading: false,
                    customerItems: [],
                }, () => {
                    if(callback){
                    callback([]);
                    }
                });
                }
            }, (error, type) => {
                this.setState({
                customerItemsIsLoading: false,
                customerItems: [],
                }, () => {
                if(callback){
                    callback([]);
                }
                });
            });
        });
    }

  callReadSettingsApi = () => {
    const { accessToken } = this.context;
    let company = window.localStorage.getItem('company_id');
    Api.read('profile/admins', {}, accessToken)
    .then(result => {
        // console.log(result)
        apiUtil.parseResult(result, data => {
            // const res = (data.result && Object.keys(data.result).length > 0 ) ? data.result : {}
            // setCurrentMeasurementData(res);
            if(data.result && Object.keys(data.result).length > 0) {
                this.setState({
                    personalFirstName:data.result.first_name,
                    personalLastName:data.result.last_name,
                    personalEmail:data.result.email,
                    personalContactNo:data.result.contact_no,
                    organizationId: window.btoa( company ),
                    personalProfileImageURL: data.result.profile_image_url,
                    personalProfileOldData:data.result,
                    data:data.result,
                })
                let user = JSON.parse(window.localStorage.getItem("user"));
                user.owner_info = data.result;
                window.localStorage.setItem('user', JSON.stringify(user));

            }
        }, (error, type) => {
        })
    })
}

  settingsAccountProfile = () => {
    return <Box className="pb-2"><Grid container p={1} >
            <Box clone>
                <Grid item xs={12}>
                    <div style={{
                            position: 'relative',
                            display: 'flex',
                            height: '260px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                    }}>
                        <CustomCropper
                            className={'no-shadow profile-customer'}
                            path={'ProfilePicture'}
                            initialImg={this.state.personalProfileImageURL}
                            onLoad={() => {
                                this.setState({
                                    isLoading: true
                                });
                            }}
                            onUpload={(url) => {
                                if(url && url.result && url.result.image_medium_url && url.result.image_medium_url && url.result.image_medium_url !== ''){
                                    this.setState({
                                        personalProfileImageURL: url.result.image_medium_url
                                    });
                                }
                            }}
                            onEnd={() => {
                                this.setState({
                                    isLoading: false
                                });
                            }}
                        />
                    </div>
                </Grid>
            </Box>
            <div className="fs-4 font-medium mb-4">Personal Information</div>
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <CustomInput
                        label={'First Name'}
                        placeholder={'Enter First Name'}
                        className="input-label--horizontal"
                        value={this.state.personalFirstName}
                        error={this.state.personalNameValidated}
                        required={true}
                        onChange={e => {
                            this.setState({
                                personalFirstName: e.target.value
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <CustomInput
                        label={'Last Name'}
                        placeholder={'Enter Last Name'}
                        className="input-label--horizontal"
                        value={this.state.personalLastName} 
                        onChange={e => {
                            this.setState({
                                personalLastName: e.target.value
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <CustomInput
                        label={'Email'}
                        placeholder={'Enter Email'}
                        className="input-label--horizontal"
                        value={this.state.personalEmail}
                        error={this.state.personalEmailValidated}
                        required={true}
                        onChange={e => {
                            this.setState({
                                personalEmail: e.target.value
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <CustomInput
                        label={'Phone Number'}
                        placeholder={'enter contact number'}
                        className="input-label--horizontal"
                        value={this.state.personalContactNo? this.state.personalContactNo : ''}
                        error={this.state.contactnoValidated}
                        onChange={e => {
                            this.setState({
                                personalContactNo: e.target.value
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <CustomInput
                        label={'Organisation'}
                        className="input-label--horizontal"
                        value={this.state.organizationId}
                        disabled
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>;
}  

    changePassword = () => {
    return <Box><Grid container p={1} >
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <div className="hover"></div>
                    <CustomInput
                        label={'Current Password'}
                        placeholder={'Current Password'}
                        className="input-label--horizontal bg-gray"
                        value={window.atob(this.state.personalPassword)}
                        type="password"
                        required={true}
                        onMouseDown={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onKeyPress={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onChange={e => {
                            this.setState({
                                personalPassword: e.target.value
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <CustomInput
                        label={'New Password'}
                         placeholder={'New Password'}
                        className="input-label--horizontal"
                        value={this.state.newPassword}
                        // error={newPasswordValidated}
                        type="password"
                        required={true}
                        // autoFocus={true}
                        onChange={e => {
                            this.setState({
                                newPassword: e.target.value
                            });
                            // setNewPassword(e.target.value)
                            // setLastNameValidated((e.target.value !== '') ? false : true) 
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} className="py-2">
                    <CustomInput
                        label={'Confirm New Password'}
                        placeholder={'Confirm New Password'}
                        className="input-label--horizontal"
                        type="password"
                        value={this.state.retryPassword}
                        required={true}
                        onChange={e => {
                            this.setState({
                                retryPassword: e.target.value
                            });
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>;
} 

handleCountryChange = (value, item) => {

    let cloneCountryList = countryList.map(object => ({ ...object }));
    let filterCountry = cloneCountryList.filter((oldCountry) => {
        if(value === oldCountry.value) {
            oldCountry.value = oldCountry.offset;
            oldCountry.label = "GMT "+oldCountry.offset + "("+ oldCountry.identifier+")";;
            return oldCountry;
        }
    });
this.setState({
    timeZoneList : filterCountry,
    timeZone:filterCountry[0],
    country: value,
    countryCode: item.code,
    countryIdentifier: item.identifier,
})
}

    generalPreference = () => {
    return <Box><Grid container p={1}>
           <CustomSelectOption
                            label='Country'
                            className="w-100"
                            value={this.state.country}
                            items={this.allCountry}
                            onChange={this.handleCountryChange}

                        />
                        <CustomSelectOption
                            label={'Timezone'}
                            className="w-100"
                            value={this.state.timeZone}
                            items={this.state.timeZoneList}
                            onChange={(value) => {
                                this.setState({
                                    timeZone: value,
                                })
                                // setTimeZone(value)
                            }}
                        />
        </Grid>
    </Box>;
} 

Address = () => {
    let address = this.state.address;
    let user_customer_id = this.state.user && this.state.user.owner_info && this.state.user.owner_info.customers && this.state.user.owner_info.customers.length > 0 && this.state.user.owner_info.customers[0].id ? this.state.user.owner_info.customers[0].id : null
    let user = JSON.parse(window.localStorage.getItem('user'));
    let inner_user_id = user && user.owner_info.customers && user.owner_info.customers.length > 0 && user.owner_info.customers[0].id ? user.owner_info.customers[0].id : null
  

    if (this.state.currentRole === "Customer Template Role") {
        address = address.filter(dd => dd.customer_id === null || dd.customer_id === user_customer_id || dd.customer_id === inner_user_id);
    } else {
        address = address.filter(dd => dd.customer_id === null);
    }
    return <Container fluid className="address-overflow">
            <Row>
                <Col  xs={12} md={12} lg={12}>
                {address && address.map((dd)=> {
                    return <Card className="my-2 p-3 address-card w-100">
                    <Row>
                        <Col xs={8} md={8} lg={10}>
                            <div className="font-medium">{dd.name}</div>
                            <div>{dd.address}</div>
                            <div>{dd.building_no}</div>
                        </Col>
                       {(this.state.currentRole == 'Customer Template Role' && dd.customer_id == null) ?
                        <Col xs={4} md={4} lg={2} className="d-flex justify-content-end align-items-center m-auto">
                         
                        </Col> : 
                        <Col xs={4} md={4} lg={2} className="d-flex justify-content-end align-items-center m-auto">
                         <FontAwesomeIcon icon={faEdit} className="primary-color" onClick={()=> {
                                if (this.state.currentRole == 'Customer Template Role' && dd.customer_id == null) {
                                    apiUtil.toast('Admin Address are not allow to edit by customer !!', 'check_circle')
                                    return
                                }
                              this.setState({
                                  openUpdateAddress:true,
                                  update_data: dd,
                              })
                          }}/>
                          <FontAwesomeIcon icon={faTrash} style={{color:"#ff0000"}} className="px-2" onClick={()=> {
                            if (this.state.currentRole == 'Customer Template Role' && dd.customer_id == null) {
                                apiUtil.toast('Admin Address are not allow to delete by customer !!', 'check_circle')
                                return
                            }
                              this.setState({
                                  openDeleteDialog:true,
                                  update_data: dd,
                              })
                          }}/>
                        </Col>}
                    </Row>
                    </Card>
                   })} 
                </Col>
            </Row>
            <Row>
                <Col>
                    <div 
                    className="add-new-address"
                    onClick={()=> {
                        this.setState({
                            openCreateAddress:true,
                        })
                    }}>Add New</div>
                </Col>
            </Row>
        </Container>;
} 

deleteDialog = () => {

    return <Modal centered show={this.state.openDeleteDialog} onHide={() => {
            this.setState({
              openDeleteDialog: false,
            });
            }}>
            <Modal.Header className="p-4" closeButton>
            <div className="text-center font-medium fs-5 d-flex justify-content-center w-100">Are you sure?</div>
            </Modal.Header>
            <div className="justify-content-center p-4">
            <div className="text-center mb-4">Are you sure to delete this address?</div>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" className="mx-2 px-4 py-2 border-delete-b" onClick={() => {
                this.setState({
                  openDeleteDialog: false,
                });
              }}>
                Cancel
              </Button>
              <Button variant="primary" className="mx-2 px-4 py-2 border-delete" 
              onClick={() => {
                  this.setState({
                    openDeleteDialog: false,
                  }, () => {
                    this.callDeleteAddressApi(this.state.update_data.id);
                  })
                }}>
                Delete
              </Button>
            </div>
            </div>
          </Modal>
  }

handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if(!this.state.personalFirstName || !this.state.personalEmail) {
        if(this.state.personalEmail == "" || this.state.personalEmail == null) {
            this.setState({
                personalEmailValidated : true
            })
        }
        if(this.state.personalFirstName == "" || this.state.personalFirstName == null) {
            this.setState({
                personalNameValidated : true
            })
        }
        return;
    }

    if(this.state.personalContactNo && !apiUtil.phoneValidation(this.state.personalContactNo)) {
        this.setState({
            contactnoValidated : true
        })
        return;
    }

    if(this.state.newPassword != "" || this.state.newPassword != null) {
        if(this.state.newPassword == this.state.retryPassword) {
        } else {
            apiUtil.toast('two Passwords doesn\'t match', 'check_circle');
            return;
        }
    }

    let optionsData = [];
    if(this.state.country != this.preCountry) {
        this.state.data.application_company.application_company_settings.map((dd) => 
        {
            if(dd.group_name === "Country") {
                let newJson = {name: this.state.country, code: this.state.countryCode, identifier: this.state.countryIdentifier, offset: this.state.timeZone }
                return optionsData.push({
                        "application_company_id": dd.application_company_id,
                        "enable": true,
                        "group_name": dd.group_name,
                        "id": dd.id ,
                        "json_data": JSON.stringify(newJson),  
                        })
            }
        })
        let optionDataFormat = {application_settings_data : optionsData};
        this.callUpdateApi(optionDataFormat)
    }

    if(this.state.personalProfileImageURL !== this.state.personalProfileOldData.profile_image_url) {

        let isChangePersonalFirstName = this.state.personalFirstName !== this.state.personalProfileOldData.first_name? this.state.personalFirstName: undefined;
        let isChangePersonalLastName = this.state.personalLastName !== this.state.personalProfileOldData.last_name? this.state.personalLastName: this.state.personalLastName;
        let isChangePersonalEmail = this.state.personalEmail !== this.state.personalProfileOldData.email? this.state.personalEmail: undefined;
        let isChangePersonalPassword = this.state.newPassword != "" && (this.state.newPassword == this.state.retryPassword)? this.state.newPassword: undefined;
        let isChangePersonalContactNumber = this.state.personalContactNo  !==  this.state.personalProfileOldData.contact_no ? this.state.personalContactNo: undefined;
        let updateProfileFormatData = {"first_name": isChangePersonalFirstName, "last_name": isChangePersonalLastName, "email": isChangePersonalEmail, "password": isChangePersonalPassword, "contact_no" : isChangePersonalContactNumber, profile_image_url: this.state.personalProfileImageURL}
        this.callUpdateProfile( updateProfileFormatData, (data) => {
            apiUtil.toast('Successfully Updated!', 'check_circle');
        })
    } else {
        let isChangePersonalFirstName = this.state.personalFirstName !== this.state.personalProfileOldData.first_name? this.state.personalFirstName: undefined;
        let isChangePersonalLastName = this.state.personalLastName !== this.state.personalProfileOldData.last_name? this.state.personalLastName: this.state.personalLastName;
        let isChangePersonalEmail = this.state.personalEmail !== this.state.personalProfileOldData.email? this.state.personalEmail: undefined;
        let isChangePersonalPassword = this.state.newPassword != "" && (this.state.newPassword == this.state.retryPassword)? this.state.newPassword: undefined;
        let isChangePersonalContactNumber = this.state.personalContactNo  !==  this.state.personalProfileOldData.contact_no ? this.state.personalContactNo: undefined;
        let updateProfileFormatData = {"first_name": isChangePersonalFirstName, "last_name": isChangePersonalLastName, "email": isChangePersonalEmail, "password": isChangePersonalPassword, "contact_no" : isChangePersonalContactNumber}
        this.callUpdateProfile( updateProfileFormatData, (data) => {
            apiUtil.toast('Successfully Updated!', 'check_circle');
        })
    }
  
}

callUpdateApi = ( row, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
        isLoading: true
    })
    Api.create('batch_update/application_company_settings', row, accessToken)
    .then((result) => {

        apiUtil.parseResult(result, (data) => {
            this.setState({
                isLoading: false
            })
            if(data.successfully_update.length > 0) {
                data.successfully_update.filter(resultData => {
                    if(resultData.group_name === "Country") {
                        localStorage.setItem('Country',(this.state.country !== null && Array.isArray(this.state.country))? this.state.country.sort(): this.state.country);
                        localStorage.setItem('TimeZoneList', JSON.stringify(this.state.timeZoneList));
                        localStorage.setItem('TimeZone', this.state.timeZone);
                    }
                });

                // setIsCustomerRequired(enableCustomer);
            }
            if(callback) {
                callback();
            }
        }, (error, type) => {
            this.setState({
                isLoading: false
            })
            if(callback) {
                callback();
            }
        });
    }).catch(
        error => console.log(error)
    )
}

callUpdateProfile = ( row, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
        isLoading: true
    })
    if(Object.keys(row).length > 0) {
        Object.assign(row, {appName:"AUTH"}); 
    }
    Api.update('profile/admins', row, accessToken)
    .then((result) => {
        apiUtil.parseResult(result, (data) => {
            this.setState({
                isLoading: false
            })
            this.callReadSettingsApi();
            // setTimeout(function(){ window.location.reload(); },1000);
            if(callback) {
                callback(data.result);
            }
        }, (error, type) => {
            this.setState({
                isLoading: false
            })
            if(callback) {
                callback(null);
            }
        });
    }).catch(
        error => console.log(error)
    )
}

callAddressApi = () => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoading: true,
      }, () => {
        let param = {
          sort: 'latest'
        };
  
        wmsApi.read('warehouses', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                address: (data.result && data.result.length > 0) ? data.result : [],
                cloneAddress: _.cloneDeep(data.result),
                total: data.total,
                isLoading: false,
              });
            }, (error, type) => {
              this.setState({
                data: [],
                total: 0,
                isLoading: false,
              });
            });
        });
      });
}

callCreateAddressApi = (data = null) => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoading: true,
      }, () => {
        let address = this.state.address;
        let user_customer_id = this.state.user && this.state.user.owner_info && this.state.user.owner_info.customers && this.state.user.owner_info.customers.length > 0 && this.state.user.owner_info.customers[0].id ? this.state.user.owner_info.customers[0].id : null
        let user = JSON.parse(window.localStorage.getItem('user'));
        let inner_user_id = user && user.owner_info.customers && user.owner_info.customers.length > 0 && user.owner_info.customers[0].id ? user.owner_info.customers[0].id : null
        let param = null;
        // console.log('ssss', user_customer_id, inner_user_id)
        if (this.state.currentRole == 'Customer Template Role') {
          // console.log('before', data)
          if (data.customer_id == null) {
            data.customer_id = user_customer_id ? user_customer_id : inner_user_id
          }
          // console.log('after', data)
          param = data
        } else {
          param = data
        }
  
        wmsApi.create('warehouses', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                address: (data.result && data.result.length > 0) ? data.result : [],
                total: data.total,
                postal_code_validated: false,
                create_data: {
                  name: null,
                  address: null,
                  latitude: null,
                  longitude: null,
                  customer_id: null,
                  application_company_id: null,
                  postal_code: null,
                  building_no: null,
                },
                isLoading: false,
              });
             this.callAddressApi()
             apiUtil.toast("Successfully Updated!", 'check_circle')
            }, (error, type) => {
              if(error && error.postal_code && error.postal_code.length > 0){
                apiUtil.toast(error.postal_code[0], 'warning', 'error');
              } else if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
              }
              this.setState({
                data: [],
                total: 0,
                isLoading: false,
              });
            });
        });
      });
}

callUpdateAddressApi = (id = null, data = null) => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoading: true,
      }, () => {
        let param = data;
        wmsApi.update('warehouses/' + id, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                address: (data.result && data.result.length > 0) ? data.result : [],
                total: data.total,
                isLoading: false,
                postal_code_validated:false,
              },()=> {
                this.callAddressApi();
                apiUtil.toast("Successfully Updated!", "check_circle")
              });
            }, (error, type) => {
              if(error && error.postal_code && error.postal_code.length > 0){
                apiUtil.toast(error.postal_code[0], 'warning', 'error');
              } else if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
              }
              this.setState({
                data: [],
                total: 0,
                isLoading: false,
              });
            });
        });
      });
}

callDeleteAddressApi = (id = null) => {
    const { accessToken } = this.context;
   
    this.setState({
        isLoading: true,
      }, () => {
        let param = { };
        wmsApi.delete('warehouses/' + id, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoading: false,
                postal_code_validated:false,
              },()=> {
                this.callAddressApi();
                apiUtil.toast("Successfully Updated!", "check_circle")
              });
            }, (error, type) => {
              if(error && error.postal_code && error.postal_code.length > 0){
                apiUtil.toast(error.postal_code[0], 'warning', 'error');
              } else if(error && error !== ''){
                apiUtil.toast(error, 'warning', 'error');
              }
              this.setState({
                data: [],
                total: 0,
                isLoading: false,
              });
            });
        });
      });
}

  /* DIALOG */
  createAddressDialog = () => {
    return <Modal size='lg' centered show={this.state.openCreateAddress} onHide={() => {
        this.setState({
          openCreateAddress: false,
          postal_code_validated:false,
        });
        }}>
        <Modal.Header className="p-4" closeButton>
        <div className="text-center font-medium fs-5 d-flex justify-content-start w-100">Create New Address</div>
        </Modal.Header>
        
        <Container fluid>
          <Row className="py-2 d-flex align-items-center">
              <Col xs={12} md={3} lg={2} className="font-medium ps-4">Address Title</Col>
              <Col xs={12} md={9} lg={10}>
              <div className="max-xs-width-control-900 ps-2 w-100">
              <CustomInput
                  style={{borderRadius:'8px'}}
                  placeholder={"Address Title"}
                  value={this.state.create_data && this.state.create_data.name}
                  type={"text"}
                  onChange={(e) => {
                      let create_data = this.state.create_data
                      create_data.name = e.target.value
                      this.setState({
                        create_data : create_data
                      })
                  }}
                />
              </div>
              </Col>
          </Row>
          <Row className="d-flex align-items-center">
              <Col xs={12} md={3} lg={2} className="font-medium ps-4">Address</Col>
              <Col xs={12} md={9} lg={10}>
              <div className="max-xs-width-control-900 ps-2 w-100">
                <CustomGoogleMapSearch
                  style={{borderRadius:'8px'}}
                  placeholder={"Address"}
                  value={this.state.create_data && this.state.create_data.address}
                  onChange={(e) => {           
                      let create_data = this.state.create_data
                      create_data.address = e.target.value
                      create_data.latitude =  e.target.value.geometry && e.target.value.geometry.location && e.target.value.geometry.location.lat() ? e.target.value.geometry.location.lat() : null
                      create_data.longitude =  e.target.value.geometry && e.target.value.geometry.location && e.target.value.geometry.location.lng() ? e.target.value.geometry.location.lng() : null
                      this.setState({
                        create_data : create_data
                      })
                  }}
                  onPlacesChanged={(place, e) => {
                    let address = apiUtil.getGmapAddress(place);
                    let create_data = this.state.create_data
                    create_data.address = address
                    create_data.latitude = place.geometry.location.lat()
                    create_data.longitude = place.geometry.location.lng()
                    this.setState({
                      create_data : create_data
                    })
                  }}
                />
              </div>
              </Col>
          </Row>
          <Row className="py-2">
              <Col xs={12} md={3} lg={2}></Col>
              <Col xs={12} md={4} lg={5}>
              <div className="max-xs-width-control-900 ps-2 w-100">
              <CustomInput
                style={{borderRadius:'8px'}}
                placeholder={"Postal Code"}
                value={this.state.create_data && this.state.create_data.postal_code}
                error={this.state.postal_code_validated}
                type={"number"}
                onPaste={(e) => {
                  if (
                    /^[0-9\b]+$/.test(e.clipboardData.getData("Text")) == false
                  ) {
                    e.preventDefault();
                    return false;
                  }
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 6);
                }}
                onChange={(e) => {
                    let create_data = this.state.create_data
                    create_data.postal_code = e.target.value
                    this.setState({
                      create_data : create_data
                    })
                }}
              />
                {/* <CustomInput
                style={{borderRadius:'8px'}}
                placeholder={"Postal Code"}
                value={this.state.create_data && this.state.create_data.postal_code}
                error={this.state.postal_code_validated}
                type={"number"}
                onPaste={(e) => {
                  if (
                    /^[0-9\b]+$/.test(e.clipboardData.getData("Text")) == false
                  ) {
                    e.preventDefault();
                    return false;
                  }
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 6);
                }}
                onChange={(e) => {
                  if (/^[0-9\b]+$/.test(e.target.value)) {
                    this.setState({
                      delivery_postal_code: e.target.value,
                      isFormChanged: true,
                      delivery_postal_codeValidated:
                      e.target.value === "" ? true : false,
                    });
                  } else if (
                    this.state.delivery_postal_code != null &&
                    e.target.value == ""
                  ) {
                    this.setState({
                      delivery_postal_code: e.target.value,
                    });
                  }
                }}
              /> */}
              </div>
              </Col>
              <Col xs={12} md={5} lg={5}>
              <div className="max-xs-width-control-900 ps-2 w-100">
              <CustomInput
                  style={{borderRadius:'8px'}}
                  placeholder={" Other details (eg, unit number) "}
                  value={this.state.create_data && this.state.create_data.building_no}
                  type={"text"}
                  onChange={(e) => {
                      let create_data = this.state.create_data
                      create_data.building_no = e.target.value
                      this.setState({
                        create_data : create_data
                      })
                  }}
                />
              </div>
              </Col>
          </Row>
        </Container>
        <div className="justify-content-center p-4">
        <div className="d-flex justify-content-center">
          <Button variant="primary" className="mx-2 px-4 py-2" style={{borderRadius:'50px'}}
          onClick={() => {
            let create_data = this.state.create_data
            this.state.currentRole == 'Customer Template Role' ? create_data.customer_id = this.state.customerItems && this.state.user && this.state.user.owner_info.customers[0].id : create_data.customer_id = null;
            create_data.application_company_id = this.state.data.application_company_id
            delete create_data['items'];
            let err = false;
            if(create_data.name == null || create_data.name == '') {
              apiUtil.toast("Name can not be blank!!")
              err = true
            }
            if(create_data.address == null || create_data.address == '') {
              apiUtil.toast("Address can not be blank")
              err = true
            } else if((create_data.latitude == null && create_data.longitude == null) || (create_data.latitude == '' && create_data.longitude == '') || (create_data.latitude == undefined && create_data.longitude == undefined)) {
              apiUtil.toast("Address does entered not have Latitude/Longitude!")
              err = true
            }
            if (create_data.postal_code && !apiUtil.postalCodeValidation(create_data.postal_code)) {
              apiUtil.toast("Postal Code must be at least 2 and max 6!!")
              this.setState({
                postal_code_validated: true,
              })
              err = true
            }  else if (create_data.postal_code == null || create_data.postal_code == '') {
              this.setState({
                postal_code_validated: true,
              })
              err = true
            }
            if (err) {
              return
            }

           
              this.setState({
                openCreateAddress: false,
              }, () => {
                this.callCreateAddressApi(create_data)
              })
            }}>
            Save Changes
          </Button>
        </div>
        </div>
      </Modal>
  }

  updateAddressDialog = () => {
    return <Modal size='lg' centered show={this.state.openUpdateAddress} onHide={() => {
        this.setState({
          isLoading: true,
        },() => {
          this.setState({
            openUpdateAddress: false,
            postal_code_validated:false,
            address: this.state.cloneAddress,
            cloneAddress: _.cloneDeep(this.state.cloneAddress),
            isLoading: false,
          })
        });
        }}>
        <Modal.Header className="p-4" closeButton>
        <div className="text-center font-medium fs-5 d-flex justify-content-start w-100">Edit Saved Address</div>
        </Modal.Header>
        
      <Container fluid>
        <Row className="py-2 d-flex align-items-center">
            <Col xs={12} md={3} lg={2} className="font-medium ps-4">Address Title</Col>
            <Col xs={12} md={9} lg={10}>
            <div className="max-xs-width-control-900 ps-2 w-100">
            <CustomInput
                style={{borderRadius:'8px'}}
                placeholder={"Address Title"}
                value={this.state.update_data && this.state.update_data.name}
                type={"text"}
                onChange={(e) => {
                    let update_data = this.state.update_data
                    update_data.name = e.target.value
                    this.setState({
                      update_data : update_data
                    })
                }}
              />
            </div>
            </Col>
        </Row>
        <Row className="d-flex align-items-center">
            <Col xs={12} md={3} lg={2} className="font-medium ps-4">Address</Col>
            <Col xs={12} md={9} lg={10}>
            <div className="max-xs-width-control-900 ps-2 w-100">
              <CustomGoogleMapSearch
                style={{borderRadius:'8px'}}
                placeholder={"Address"}
                value={this.state.update_data && this.state.update_data.address}
                onChange={(e) => {           
                    let update_data = this.state.update_data
                    update_data.address = e.target.value
                    update_data.latitude =  e.target.value.geometry && e.target.value.geometry.location && e.target.value.geometry.location.lat() ? e.target.value.geometry.location.lat() : null
                    update_data.longitude =  e.target.value.geometry && e.target.value.geometry.location && e.target.value.geometry.location.lng() ? e.target.value.geometry.location.lng() : null
                    this.setState({
                      update_data : update_data
                    })
                }}
                onPlacesChanged={(place, e) => {
                  let address = apiUtil.getGmapAddress(place);
                  let update_data = this.state.update_data
                  update_data.address = address
                  update_data.latitude = place.geometry.location.lat()
                  update_data.longitude = place.geometry.location.lng()
                  this.setState({
                    update_data : update_data
                  })
                }}
              />
            </div>
            </Col>
        </Row>
        <Row className="py-2">
            <Col xs={12} md={3} lg={2}></Col>
            <Col xs={12} md={4} lg={5}>
            <div className="max-xs-width-control-900 ps-2 w-100">
            <CustomInput
                style={{borderRadius:'8px'}}
                placeholder={"Postal Code"}
                value={this.state.update_data && this.state.update_data.postal_code}
                error={this.state.postal_code_validated}
                type={"number"}
                onPaste={(e) => {
                  if (
                    /^[0-9\b]+$/.test(e.clipboardData.getData("Text")) == false
                  ) {
                    e.preventDefault();
                    return false;
                  }
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 6);
                }}
                onChange={(e) => {
                    let update_data = this.state.update_data
                    update_data.postal_code = e.target.value
                    this.setState({
                      update_data : update_data
                    })
                }}
              />
            </div>
            </Col>
            <Col xs={12} md={5} lg={5}>
            <div className="max-xs-width-control-900 ps-2 w-100">
            <CustomInput
                style={{borderRadius:'8px'}}
                placeholder={"Other details (eg, unit number) "}
                value={this.state.update_data && this.state.update_data.building_no}
                type={"text"}
                onChange={(e) => {
                    let update_data = this.state.update_data
                    update_data.building_no = e.target.value
                    this.setState({
                      update_data : update_data
                    })
                }}
              />
            </div>
            </Col>
        </Row>
      </Container>
        <div className="justify-content-center p-4">
        <div className="d-flex justify-content-center">
          <Button variant="primary" className="mx-2 px-4 py-2" style={{borderRadius:'50px'}}
          onClick={() => {
            let update_data = this.state.update_data
            update_data.application_company_id = this.state.data.application_company_id
            delete update_data['items'];
            if((update_data.latitude == null && update_data.longitude == null) || (update_data.latitude == '' && update_data.longitude == '') || (update_data.latitude == undefined && update_data.longitude == undefined)) {
                apiUtil.toast("Address does entered not have Latitude/Longitude!")
                return
            }
            if (update_data.postal_code && !apiUtil.postalCodeValidation(update_data.postal_code)) {
              apiUtil.toast("Postal Code must be at least 2 and max 6!!")
              this.setState({
                postal_code_validated: true,
              })
              return
            } else if (update_data.postal_code == null || update_data.postal_code == '') {
              this.setState({
                postal_code_validated: true,
              })
              return
            }
              this.setState({
                openUpdateAddress: false,
              }, () => {
                this.callUpdateAddressApi(update_data.id, update_data)
              })
            }}>
            Save Changes
          </Button>
        </div>
        </div>
      </Modal>
  }

  render() {
    return <Container fluid>
        <Row className="pb-4">
            <Col xs={12} md={5} className="p-5 pb-0 pe-lg-0 pe-md-0">
                <Card className="cd-shadow p-5">
                   {this.settingsAccountProfile()}
                </Card>
            </Col>
            <Col xs={12} md={7} className="p-5 pb-0">
                <Row>
                    <Col xs={12} md={12}>
                        <Card className="cd-shadow p-5 mb-3">
                            <div className="fs-4 font-medium mb-4">Change Password</div>
                            {this.changePassword()}
                        </Card>
                    </Col>

                    <Col xs={12} md={12} className="mt-2 pt-1">
                        {this.state.isLoading ? 
                         <Card className="cd-shadow p-5 mb-3 d-flex justify-content-center align-items-center" style={{height:"270px"}}>
                             <CircularProgress size={35} />
                         </Card> :
                        <Card className="cd-shadow p-5 mb-3">
                        <div className="fs-4 font-medium mb-4">Saved Addresses</div>
                        {/* {this.generalPreference()} */}
                        {this.Address() }
                        </Card>
                        }
                      
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="pb-4">
            <Col>
            <div className="d-flex justify-content-center align-items-center">
                <Button className="upload-style" onClick={this.handleSubmit}>Save Changes</Button>
            </div>
            </Col>
        </Row>
            {this.updateAddressDialog()}
            {this.createAddressDialog()}
            {this.deleteDialog()}
        </Container>
  }
}

export default CustomerSetting;
