import React, { useState } from 'react';

function CustomSwitchClient(props) {
  const [isChecked, setIsChecked] = useState(props.value);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  return (
    <label className="switch">
      <input type="checkbox" onChange={toggleSwitch} checked={isChecked} />
      <span className={`slider ${isChecked ? 'checked' : 'unchecked'}`}>
        {isChecked ? <CheckmarkIcon /> : <XIcon />}
      </span>
    </label>
  );
}

function CheckmarkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.5 8.88571L7.9643 12.35L13.6786 6.63571"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function XIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1 1L15 15M1 15L15 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CustomSwitchClient;
